const tailwindConfig = require('./tailwind.config.js')
const tailwindColors = tailwindConfig.theme.extend.colors

export const getHexFromTailwindColor = (tailwindColor) => {
  if(tailwindColors[tailwindColor]) return tailwindColors[tailwindColor]
  return '#FFFFFF'
}


export const statusColor = ({ percent, returnType, displayMode }) => {
  const num = percent > 1 ? 'bad' : percent > 0.97 ? 'okay' : 'good'
  const key = displayMode + '-' + num
  const finalClass = tailwindConfig.theme.colors[key]

  if(returnType === 0){
    return finalClass
  }if(returnType === 1){
    return 'text-' + num
  }else{
    return 'ERROR'
  }

}