import { useState } from "react"
import { fbSignIn, auth, fbSignOut } from "../config/fbConfig"
import { useAuthState } from 'react-firebase-hooks/auth';

export default function Login() {

  const [email,setEmail] = useState("")
  const [pass,setPass] = useState("")
  const [FBuser,FBuserLoading] = useAuthState(auth);

  const emailChange = (e) => {
    setEmail(e.target.value)
  }

  const passChange = (e) => {
    setPass(e.target.value)
  }

  const submit = () => {
    fbSignIn(email,pass).then(t => console.log(t))
    setEmail("")
    setPass("")
  }

  const signOut = () => {
    fbSignOut()
  }
  
  
  if(FBuserLoading) return null

  return (
    <div>
      { FBuser == null ? 
        <form>
          <input type="email" onChange={emailChange} value={email} />
          <input type="password" onChange={passChange} value={pass} />
          <button onClick={submit} type="submit">Submit</button>
        </form> :
        <button onClick={signOut}>Signout</button>
      }
    </div>
  )
}