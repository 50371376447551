import React from 'react'
import Markdown from 'react-markdown'
import ArticleTitle from './ArticleContent/ArticleContentComponents/ArticleTitle'
import remarkDirective from 'remark-directive'
import {visit} from 'unist-util-visit'
import {h} from 'hastscript'
import ArticleDate from './ArticleContent/ArticleContentComponents/ArticleDate'
import ArticleHeader2 from './ArticleContent/ArticleContentComponents/ArticleHeader2'
import ArticleHeader3 from './ArticleContent/ArticleContentComponents/ArticleHeader3'
import ArticleLink from './ArticleContent/ArticleContentComponents/ArticleLink'
import ArticlePara from './ArticleContent/ArticleContentComponents/ArticlePara'
import ArticleUnordered from './ArticleContent/ArticleContentComponents/ArticleUnordered'
import ArticleImage from './ArticleContent/ArticleContentComponents/ArticleImage'
import ArticleDisclaimer from './ArticleContent/ArticleContentComponents/ArticleDisclaimer'



export default function RenderArticleContent({ markdown }) {

const componentMap = {
  h1: ArticleTitle,
  h2: ArticleHeader2,
  h3: ArticleHeader3,
  articledate: ArticleDate,
  a: ArticleLink,
  p: ArticlePara,
  ul: ArticleUnordered,
  img: ArticleImage,
  disclaimer: ArticleDisclaimer
}


function htmlDirectives() {
  return transform

  function transform(tree) {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], ondirective)
  }

  function ondirective(node) {
    var data = node.data || (node.data = {})
    var hast = h(node.name, node.attributes)

    // console.log(hast)

    data.hName = hast.tagName
    data.hProperties = hast.properties
  }
}

  return (
    <Markdown
    components={componentMap}
    remarkPlugins={[remarkDirective,htmlDirectives]}
    >{markdown}</Markdown>
  )
}