import useArticles from "../../hooks/useArticles";
import ArticleListItem from "./ArticleListItem";


export default function ArticlesList () {

  const [articles,loading] = useArticles();


  return (
    <div className="ArticleList w-full h-full pt-5 pb-5 overflow-scroll">
      <div className="flex flex-col space-y-5 w-full">
      <p className="text-5xl col-span-4 pl-8 text-primary">Latest Articles</p>
        { loading ? 
          <p className="text-xl ml-10">Loading...</p> :
          articles && !loading && articles.map(a => {
            return <ArticleListItem key={a.id} article={a} />
          })
        }
      </div>
    </div>
  )
}