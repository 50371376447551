import { useNavigate } from "react-router-dom"
import moment from "moment"
import main_logo from '../../images/main_logo.svg'


export default function ArticleListItem({ article }) {

  const navigate = useNavigate()

  const description = article.lead
  const title = article.title

  const truncatedDescription = description.substring(0,500) + '...'

  const click = () => {
    navigate("/articles/" + article.id)
  }
  
  const imageSrc = article.imageURL ? article.imageURL : main_logo;

  return (

  <div onClick={click} className="flex w-2/3 ml-20 animateHover cursor-pointer bg-slate-300 border-black border-2 rounded-lg">
    <div className="h-40">
    <img className="h-40 w-40 bg-white rounded-l" src={imageSrc} alt="" />
    </div>
    <div className="flex content-center w-[1000px] h-40">
      <div className="  w-full text-primary" >
        <div className="p-2">
          <p className="text-xl">{ title }</p>
          <p className="">{moment.unix(article.createdDate.seconds).format("M/DD/YYYY")}</p>
          <p>{truncatedDescription}</p>
        </div>
      </div>
    </div>
  </div>
  )
}