import { useLocation, useNavigate } from "react-router-dom"
import { NavLink } from 'react-router-dom';



export default function NavBarLink({ linkConfig }) {

  const loc = useLocation()
  const nav = useNavigate()

  const title = linkConfig.title
  const to = linkConfig.to
  const activeMatch = linkConfig.activeMatch
  const matchChars = linkConfig.matchChars

  const isActive = () => {
    if(matchChars === 0) return loc.pathname === activeMatch
    return loc.pathname.substring(0,0+matchChars) === activeMatch
  }

  const click = () => {
    nav(to)
  }

  return (
    <li className={`cursor-pointer nav-link ` + (isActive() ? 'active' : '')} onClick={click}><NavLink to={to}>{ title }</NavLink></li>
  )
}