
import { useState, useEffect } from "react";
import { singleLineToMultiline } from "../temp";
// import { testContent } from '../temp'
import { articleContentDoc } from '../config/fbConfig'
import { getDoc } from "firebase/firestore";

const useArticleContent = (articleID) => {
  const [data, setData] = useState("");

  useEffect(() => {

    const articleDocRef = articleID ? articleContentDoc(articleID) : null

    getDoc(articleDocRef).then(docSnap => {
      setData(singleLineToMultiline(docSnap.data().markdown))
      // setData(testContent)
    })

    
  }, [articleID]);

  return {markdown: data};
};

export default useArticleContent;