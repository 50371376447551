// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, getFirestore } from 'firebase/firestore'
// import { getAnalytics, logEvent, setUserProperties, setUserId } from "firebase/analytics";
// import { getRemoteConfig } from "firebase/remote-config";
// import { isLocal } from "../helpers/environment";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzBrqlSoBpuAhVz8M2qqNyH5Hx7BW8SNU",
  authDomain: "doodad-capital.firebaseapp.com",
  databaseURL: "https://doodad-capital.firebaseio.com",
  projectId: "doodad-capital",
  storageBucket: "doodad-capital.appspot.com",
  messagingSenderId: "811654475890",
  appId: "1:811654475890:web:7eaeea71c7d56f932192eb",
  measurementId: "G-SLSLSBMB4H"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore()
export const auth = getAuth()

// const analytics = getAnalytics(app);
// export const setAdmin = (isAdmin) => setUserProperties(analytics, { isAdmin });
// export const setUserID = (userID) => setUserId(analytics, userID);
// export const logAnalyticsEvent = (eventName,params) => { 
//   if(!isLocal){
//     logEvent(analytics,eventName,params) 
//   }
// }

// export const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings.minimumFetchIntervalMillis = 21600000;
// remoteConfig.defaultConfig = {
//   popup_cta: {"text":"","enabled":false,"link":{"text":"","enabled":false,"url":""}}
// };


export const currentUserID = () => {
  return auth.currentUser.uid
}

export const articleDoc = (articleID) => doc(db,'articles',articleID)
export const articleContentDoc = (articleID) => doc(db,'articles',articleID,'content','1')

//Collections
export const articlesCollection = () => collection(db,'articles')

export const fbSignIn = async (email, password) => {
  return new Promise(async (resolve) => {
    signInWithEmailAndPassword(auth, email, password).then((j) => {
      resolve(null)
    }).catch((err) => {
      if(err.code === 'auth/wrong-password'){
        resolve('Wrong Password')
      }else if(err.code === 'auth/user-not-found'){
        resolve('There is no user with this email')
      }else if(err.code === 'auth/invalid-email'){
        resolve('Invalid Email')
      }else{
        resolve(err.code)
      }
      return;
    })
  })
}

export const fbSignOut = () => {
  return new Promise(async (resolve) => {
    auth.signOut().then(() => {
      window.location.reload()
    })
  })
}


// export const changePass = async (creds) => {
//   return new Promise(async (resolve) => {

//     if(creds.newPass !== creds.repeatNewPass){
//       resolve({success:false,error:'Passwords do not match'})
//       return
//     }
  
//     const user = auth.currentUser
//     signInWithEmailAndPassword(
//       auth,
//       user.email,
//       creds.currentPass
//     ).then((d) => {
//       updatePassword(user,creds.newPass).then(() => {
//         resolve({success:true,error:''})
//       }).catch(error => {
//         console.log(error)
//         resolve({success:false,error:error.code})
//       })
//     }).catch((err) => {
//       console.log(err)
//       resolve({success:false,error:'Wrong Password'})
//     })
//   })
// }