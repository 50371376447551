import { LineChart } from '@mui/x-charts/LineChart';
import { getHexFromTailwindColor } from '../../../../styles/colorClasses';

export default function PriceChart({ priceData }) {

  const color = getHexFromTailwindColor('primary')


  const xData = priceData.xData
  const yData = priceData.yData

  return (
    <LineChart
      xAxis={[{ data: xData }]}
      series={[
        {
          curve: "linear",
          showMark: false,
          data: yData,
          color
        },
      ]}
      // width={1000}
      height={500}

    />
  );
}