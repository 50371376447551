import Nav from "./Nav/Nav";
import { Routes, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Articles from './Articles/Articles'
import Article from './Articles/Article'
import Dashboard from './Dashboard'
import useWindowDimensions from "../hooks/useWindowDimensions";
import Login from "../auth/Login";
import ArticleWriter from "./Articles/ArticleWriter/ArticleWriter";


export default function Main() {

  const windowDimensions = useWindowDimensions()

  const heightOfContent = windowDimensions.height - 112;

  const style = {
    "height": heightOfContent
  }
  
  return (
    <div className="flex flex-col h-screen">
      <div className="">
        <Nav />
      </div>
      <div style={style} className="overflow-scroll">
        <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/articles" element={<Articles />}/>
            <Route path="/articles/:id" element={<Article />}/>
            <Route path="/dashboard" element={<Dashboard />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/writer" element={<ArticleWriter />}/>
          </Routes>
      </div>
    </div>
  )
}