import { useState } from "react"
import { useParams } from "react-router-dom"
import RenderArticleContent from "./RenderArticleContent"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import PriceChart from "./ArticleContent/Stats/PriceChart"
import DoubleRightArrowIcon from '../icons/DoubleRightArrowIcon'
import DoubleLeftArrowIcon from "../icons/DoubleLeftArrowIcon"
import useArticleContent from "../../hooks/useArticleContent"

export default function Article() {

  const [isCollapsed,setIsCollapsed] = useState(true)

  const params = useParams()
  const windowDimensions = useWindowDimensions()
  const article = useArticleContent(params.id)

  const heightOfContent = windowDimensions.height - 112;

  const style = {
    "height": heightOfContent
  }


  const gaussianRandom = (mean=0, stdev=.208)  => {
    let u = 1 - Math.random(); //Converting [0,1) to (0,1)
    let v = Math.random();
    let z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    // Transform to the desired mean and standard deviation:
    return z * stdev + mean;
  }

  const getData = () => {
    const max = 100;
    const trend = .005
    var currentPrice = 100

    const xData = []
    const yData = []

    for(var i=0; i<=max; i++){

      xData.push(i)

      const newPrice = currentPrice + trend + gaussianRandom();

      yData.push(newPrice)
      currentPrice = Math.max(newPrice,0);
    }

    return {
      xData, yData
    }

  }

  const collapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  
  const articleWidthClass = isCollapsed ? '' : '';
  const statsDisplayClass = isCollapsed ? 'w-10' : 'w-[750px]'
  const articleContentsClass = isCollapsed ? 'px-10 w-3/4' : 'px-5'
  const iconClass = isCollapsed ? 'justify-center' : 'flex-row-reverse'

  return (
    <div style={style} className="Article justifycenter w-full flex ">
      <div className={"ArticleContentContainer h-full w-full flex justify-center shrink transition-all ease-in-out " + articleWidthClass}>
        <div className={"transition-all ease-in-out pt-5 pb-5 h-full overflow-scroll noScroll " + articleContentsClass} >
          <RenderArticleContent markdown={article.markdown} />
        </div>
      </div>
      <div className={"hidden pt-2 pl-2 grow overflow-scroll noScroll border-l-slate-800 border-l-2 transition-all ease-in-out " + statsDisplayClass}>
        <div className={"tran"}>
          <div className={"w-full flex " + iconClass}>
            <div className="h-4 cursor-pointer" onClick={collapse}>
              { isCollapsed ? <DoubleLeftArrowIcon /> : <DoubleRightArrowIcon /> }
            </div>
          </div>
          <div className={(isCollapsed ? "hidden" : "block")}>
            <p className="text-3xl">Quick Look</p>
            <p>As of close on 06/21/2020</p>
            <p className="text-xl mt-5">1 Year Chart</p>
            <PriceChart priceData={getData()} />
            <p className="text-xl mt-5">Key Stats</p>
            <p>P/E: 23.4</p>
            <p>Market Cap: $495.43B</p>
            <p>Current Ratio: 1.3</p>
            <p>Debt/Equity: 10</p>
            <p className="text-xl mt-5">Trends</p>
            <p>1 month: +19%</p>
            <p>3 month: +23%</p>
            <p>6 month: -45%</p>
            <p className="text-xl mt-5">Relevant tickers</p>
            <p>GOOG</p>
            <p>AAPL</p>
          </div>
        </div>
      </div>
    </div>
  )
}