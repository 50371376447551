import React from "react"

export default function ArticleTitle({ children }) {


  return (
    <>
      <p className="ArticleTitle">{ children }</p>
    </>
  )
}