import React from 'react';
import main_logo from '../../images/main_logo.svg'
import { NavLink } from 'react-router-dom';
import NavBarLink from './NavBarLink';


export default function Nav() {

  //h-28 = 112 px

  const navLinks = [
    {title: 'Home', to: '/', activeMatch: '/', matchChars: 0 },
    {title: 'Articles', to: '/articles', activeMatch: '/articles', matchChars: 9 }
  ]

  return (
    <div className="Nav h-28 w-screen px-5 py-5 text-lg font-bold">
      <ul className="nav-list w-full flex justify-between">
        <li><NavLink to="/"><img src={main_logo} alt="logo"></img></NavLink></li>
        <div className="flex space-x-3 items-center ">
          { navLinks && navLinks.map((navLink,i) => {
            return <NavBarLink key={i} linkConfig={navLink} />
          })}
        </div>
      </ul>
    </div>
  )
}