
import { useState, useEffect } from "react";
// import { testContent } from '../temp'
import { articlesCollection } from '../config/fbConfig'
import { getDocs, orderBy, query, where } from "firebase/firestore";

const useArticles = () => {
  const [data, setData] = useState("");
  const [loading,setLoading] = useState(true)

  useEffect(() => {

    const q = query(articlesCollection(), where("isLive","==",true), orderBy("createdDate","desc"))

    const newData = []

    setLoading(true)
    getDocs(q).then(querySnapshot => {
      const docs = querySnapshot.docs
      for(var i = 0; i < docs.length; i++){
        newData.push({...docs[i].data(),id:docs[i].id})
      }
      setData(newData)
      setLoading(false)
    })

    
  }, []);

  return [data,loading];
};

export default useArticles;