import { useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

export default function Home() {

  const nav = useNavigate()

  const enlighten = () => {
    nav('/articles')
  }

  const transitionValues = {
    duration: 2,
  repeat: Infinity,
    ease: "easeInOut"
  };

  return (
    <div className="text-center flex flex-col">
      <p className="home-text self-center">Hello, we are <span className="home-highlight">Doodad Capital</span>.</p>
      <p className="home-text self-center">We specialize in equity research for <span className="home-highlight">traditionally uncovered companies</span>.</p>
      <motion.div 
        transition={{
          y: transitionValues,
        }}
        animate={{
          y: ["0px","-10px", "0px"]
        }}
        className="self-center mt-10 bg-primary p-3 rounded-lg cursor-pointer" 
        onClick={enlighten}
        >
        <p className="text-4xl text-white">Discover</p>
      </motion.div>
    </div>
  )
}