
const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

const primaryColor = "#0A1278"

module.exports = {
  content: [
    './src/**/*.html',
    './src/**/*.js',
    './src/**/*.jsx'
  ],
  theme: {
    extend: {
      fontFamily: {
        newsreader: ["Newsreader"],
        mukta: ["Mukta"]
      },
      colors:{
        primary: "#0A1278",
        "primary-light": setOpacity(primaryColor,0.6),
        "primary-light-extra": setOpacity(primaryColor,0.3)
      }
    },
  },
  plugins: [],
}